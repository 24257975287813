import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.css';

// Data
import data from './data';

// Components
import Loader from './components/Loader';
import Header from './layout/Header';
import Footer from './layout/Footer';

// Pages
const Home = React.lazy(() => import('./pages/home'));
const Services = React.lazy(() => import('./pages/services'));
const Gallery = React.lazy(() => import('./pages/gallery'));
const Contact = React.lazy(() => import('./pages/contact'));
const MessageSend = React.lazy(() => import('./pages/messageSend'));


export default function App() {
  const { i18n } = useTranslation();
  const [allData, setAllData] = useState([]);
  const papaParseConfigObj = {
    header: true,
    dynamicTyping: true
  }
  async function GetData(language) {
    const data = Papa.parse(await fetchCsv(language),papaParseConfigObj);
    let result = data.data;
    result.forEach(row => {row.tags = row.tags.split(',')});
    // console.log(result);
    return result;
  }

  async function fetchCsv(language) {
    const response = await fetch('data/gallery/'+language+'.csv');
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder('utf-8');
    const csv = await decoder.decode(result.value);
    return csv;
  }
  const [galleryData, setGalleryData] = useState();
  useEffect(() => {
    if (i18n.language === 'cn') {
      GetData('cn')
      .then(data => setGalleryData(data));
      setAllData(data.cn);
    } else {
      GetData('en')
      .then(data => setGalleryData(data));
      setAllData(data.en);
    }
  }, [i18n.language]);

  return (
    <Router>
      <Header data={allData} i18n={i18n} />
      <React.Suspense fallback={<Loader />}>
        <main>
          <Switch>
            <Redirect
              from='/:url*(/+)'
              to={window.location.pathname.slice(0, -1)}
            />
            <Route
              path="/home"
              render={(props) => <Home {...props} data={allData.Home} />}
            />
            <Route
              path="/services"
              render={(props) => <Services {...props} data={allData.Services} />}
            />
            <Route
              path="/gallery"
              render={(props) => <Gallery {...props} data={allData.Gallery} galleryData={galleryData} />}
            />
            <Route
              path="/contact"
              render={(props) => <Contact {...props} data={allData.Contact} />}
            />
            <Route
              path="/messageSend"
              render={(props) => <MessageSend {...props} data={allData.messageSend} />}
            />
            {/* Redirect */}
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </main>

        <Footer data={allData} />
      </React.Suspense>
    </Router>
  );
} 
