import React from 'react';

export default function Footer({ data }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div id="footer">
        <div>
          (437)224-4191 (中文)
        </div>
        <div>
          (647)518-7866 (En)
        </div>
        <div>
          hongsenlandscaping@gmail.com
        </div>
        <div id="copyright">
          ©{currentYear} by Hongsen Landscaping
        </div>
      </div>
    </footer>
  );
}
