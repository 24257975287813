const data = {
  /************ ENGLISH **************/
  en: {
    "Home": {
      "title": "Hongsen Landscaping",
      "paragraph": "Trustworthy Landscaping Service in GTA"
    },
    "Gallery": {
      title: "Gallery",
      interlocking: 'Interlocking',
      stairs: 'Stairs',
      retainingwall: 'Retaining Wall',
      lighting: 'Lighting',
      firepit: 'Firepit',
      waterfall: 'Water Fall',
      sodding: 'Sodding',
      treewall: 'Tree Wall'
    },
    "Services": {
      "title": "Our Services",
      "list": [
        {
          "icon": "fa fa-puzzle-piece",
          "name": "Interlocking & Concrete",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-car",
          "name": "Driveway & Patio",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-arrows-alt",
          "name": "Flower Bed & Retaining Walls",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-sliders",
          "name": "Steps & Natural Stones",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-fire",
          "name": "Fire Put & Outdoor Kitchen",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-pie-chart",
          "name": "Deck & Fence",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-leaf",
          "name": "Sodding & Planting",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        }
      ]
    },
    "Contact": {
      "title": "GET IN TOUCH",
      "desc": "Please fill out the form below to send us an email and we will get back to you as soon as possible.",
      "address": "70 Hendon Road, Markham ON L3R 6Z5",
      "phone": "(647)553-7688",
      "email": "hongsenlandscaping@gmail.com",
      "facebook": "fb.com",
      "twitter": "twitter.com",
      "youtube": "youtube.com"
    },
    menu1: 'home',
    menu2: 'services',
    menu3: 'gallary',
    menu4: 'contact',
    messageSend: 'We have received you request and will contact you shortly.'

  },
  /************ CHINESE **************/
  cn: {
    "Home": {
      "title": "洪森园艺",
      "paragraph": "服务大多伦多地区"
    },
    "About": {
      "paragraph": "about.paragraph",
      "Why": [
        "about.why.1",
        "about.why.2",
        "about.why.3",
        "about.why.4"
      ],
      "Why2": [
        "about.why2.1",
        "about.why2.2",
        "about.why2.3",
        "about.why2.4"
      ]
    },
    "Gallery": {
      title: "项目展示",
      interlocking: '交错铺砖',
      stairs: '台阶',
      retainingwall: '挡土墙',
      lighting: '灯光',
      firepit: '火坑',
      waterfall: '流水瀑布',
      sodding: '草皮',
      treewall: '树墙'
    },
    "Services": {
      "title": "服务项目",
      "list": [
        {
          "icon": "fa fa-puzzle-piece",
          "name": "交错铺砖和混凝土",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-car",
          "name": "车道和露台",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-arrows-alt",
          "name": "花坛和挡土墙",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-sliders",
          "name": "台阶和天然石材",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-fire",
          "name": "火坑和户外厨房",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-pie-chart",
          "name": "甲板和围栏",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
          "icon": "fa fa-leaf",
          "name": "草坪铺设和园艺种植",
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        }
      ]
    },
    "Contact": {
      "title": "联系我们",
      "desc": "请填写您的联系方式和需求，我们会尽快联络您",
      "address": "70 Hendon Road, Markham ON L3R 6Z5",
      "phone": "(437)224-4191",
      "email": "hongsenlandscaping@gmail.com",
      "facebook": "fb.com",
      "twitter": "twitter.com",
      "youtube": "youtube.com"
    },
    menu1: '主页',
    menu2: '服务项目',
    menu3: '项目展示',
    menu4: '联系我们',
    messageSend: '我们已经收到您的消息，将会尽快联络您。'
  },
};

export default data;
