import React from 'react';
import { NavLink } from 'react-router-dom';

import SwitchLang from './SwitchLang'

export default function Header({ data, i18n }) {
  const menuItems = [
    {
      title: data.menu1,
      uri: '/home',
    },
    {
      title: data.menu2,
      uri: '/services',
    },
    {
      title: data.menu3,
      uri: '/gallery',
    },
    {
      title: data.menu4,
      uri: '/contact',
    },
  ];

  return (
    <header>
      <div className="navbar">
        <div className='logo'>
          <a href='/home'>
            <img src="/img/favicon.png"></img>
          </a>
          <div>
            Hongsen Landscaping
          </div>
         </div>
        <SwitchLang i18n={i18n} />
        <div>
          {menuItems.map((item) => (
            <NavLink key={item.uri} to={item.uri} activeClassName="active">
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </header>
  );
}
